<template>
  <div class="container">
    <el-card>
      <div slot="header" style="display: flex;">
        <div style="margin-right: 20px;">
          <i class="el-icon-location" style="margin-right: 5px; color: cornflowerblue;"></i>
          <span class="defaultfont" style="margin-right: 5px;">选择班级:</span>
          <el-select placeholder="请选择" v-model="NowClass">
            <el-option v-for="item in allClass" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>

        </div>
        <div style="display: flex;">
          <div style="margin-right: 10px;display: flex; ">
            <i class="el-icon-search" style="margin: auto; margin-right: 5px; color: cornflowerblue;"></i>
            <span class="defaultfont" style="width: 180px; margin: auto;">本班级学生搜索:</span>
            <el-input style="margin: auto;" placeholder="请输入学生名字" v-model="input" clearable>
            </el-input>
          </div>
          <el-button type="primary" round @click="clickTabButton">确定</el-button>
        </div>
      </div>
      <div>
        <el-table :data="classStudent" style="width: 100%">
          <el-table-column align="center" prop="no" label="学生编号" width="100">
          </el-table-column>
          <el-table-column label="姓名" align="center">
            <template slot-scope="scope">
              <div slot="reference">
                <el-tag size="medium">{{ scope.row.name }}</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="性别" align="center" width="100">
            <template slot-scope="scope">
              <div slot="reference" class="name-wrapper">
                <el-tag size="medium">{{ scope.row.sex == 0 ? "男" : "女" }}</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="班级" align="center">
            <template slot-scope="scope">
              <div slot="reference">
                {{ scope.row.classes.name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="card" label="身份证号" align="center">
          </el-table-column>
          <el-table-column label="班主任" align="center">
            <template slot-scope="scope">
              <div slot="reference">
                <el-tag size="medium">{{ scope.row.classes.kxTeacher.name }}</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <div slot="reference">
                <el-button type="success" plain @click="clickStuButton(scope.row.id)">查看学生详情</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
  </div>
</template>

<script>
import { GetAllClass, GetSemester, GetClassStudent } from "../../api/thing"
export default {
  name: "charts",
  data() {
    return {
      allClass: [],
      NowClass: 1,
      classStudent: [],
      NowSemester: 0,
      input: "",
    };
  },
  created() {
    this.initToClassRoom()
  },
  methods: {
    initToClassRoom() {
      GetSemester().then(val => {
        this.NowSemester = val.now.id;
        GetClassStudent(this.NowClass, val.now.id).then(value => {
          this.classStudent = value.rows
        })
      })
      GetAllClass().then(val => {
        this.allClass = val.rows;
      })

    },
    clickTabButton() {
      this.classStudent = []
      GetClassStudent(this.NowClass, this.NowSemester).then(value => {
        if (this.input !== "") {
          this.classStudent = value.rows.filter(row => {
            // 在这里根据您的需求确定要过滤的条件
            // 这里假设您要过滤出包含 input 的行
            return row.name.includes(this.input);
          })
        } else {
          this.classStudent = value.rows
        }

      })
    },
    clickStuButton(val) {
      this.$router.push("/student/"+val);
    }
  }
};
</script>

<style scoped></style>